if (document.body.className.match('reviews-new') || document.body.className.match('reviews-edit') ) {
    $(".crumina-submit #account_type").change(function () {

        // console.log($('.crumina-submit #account_type').find(":selected").data('placeholder'));
        // console.log($('.crumina-submit #account_type').find(":selected").data('name'));

        $(".crumina-submit #link_field" ).attr( "name", "review[" + $('.crumina-submit #account_type').find(":selected").data('name') + "]");
        $(".crumina-submit #link_field" ).attr( "placeholder", $('.crumina-submit #account_type').find(":selected").data('placeholder'));
        $(".crumina-submit .label_link" ).text( $('.crumina-submit #account_type').find(":selected").data('label'));

    });


    $("#review_anon").change(function () {
        $('.nickname_area').empty();
        // console.log( $('#review_anon').find(":selected").val() );
        console.log(I18n.t('models.review.author_nickname'));
        if ($('#review_anon').find(":selected").val() == 'true') {
            $('.nickname_area').append(('<label class="control-label">' + I18n.t('models.review.author_nickname') + '</label>'));
            $('.nickname_area').append('<input placeholder="username" class="form-control bg-white" type="text" value="' + I18n.t('shared.all.anon') + '" name="review[username]" id="review_username">');
            $('.nickname_area').append('<span class="material-input"></span>');
            $('#nickname_wrapper').removeClass( "d-none" );
        } else if ($('#review_anon').find(":selected").val() == 'false') {
            $('.nickname_area').empty();
            $('#nickname_wrapper').addClass( "d-none" );

        }
    });

    let searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('instagram_nickname')) {
        $("#create-review #account_type").val("instagram").change();
        $("#create-review #link_field" ).val(searchParams.get('instagram_nickname')).change();

    } else if (searchParams.has('facebook_nickname')) {
        $("#create-review #account_type").val("facebook").change();
        $("#create-review #link_field" ).val(searchParams.get('facebook_nickname'));

    } else if (searchParams.has('phone')) {
        $("#create-review #account_type").val("phone").change();
        $("#create-review #link_field" ).val(searchParams.get('phone'));

    } else if (searchParams.has('email')) {
        $("#create-review #account_type").val("email").change();
        $("#create-review #link_field").val(searchParams.get('email'));

    } else if (searchParams.has('vk_nickname')) {
        $("#create-review #account_type").val("vk").change();
        $("#create-review #link_field" ).val(searchParams.get('vk_nickname'));

    } else if (searchParams.has('twitter_nickname')) {
        $("#create-review #account_type").val("twitter").change();
        $("#create-review #link_field" ).val(searchParams.get('twitter_nickname'));

    } else if (searchParams.has('youtube_nickname')) {
        $("#create-review #account_type").val("youtube").change();
        $("#create-review #link_field" ).val(searchParams.get('youtube_nickname'));

    }
    else if (searchParams.has('twitch_nickname')) {
        $("#create-review #account_type").val("twich").change();
        $("#create-review #link_field" ).val(searchParams.get('twich_nickname'));

    } else if (searchParams.has('tiktok_nickname')) {
        $("#create-review #account_type").val("tiktok").change();
        $("#create-review #link_field" ).val(searchParams.get('tiktok_nickname'));

    } else if (searchParams.has('linkedin_nickname')) {
        $("#create-review #account_type").val("linkedin").change();
        $("#create-review #link_field" ).val(searchParams.get('linkedin_nickname'));

    } else if (searchParams.has('reddit_nickname')) {
        $("#create-review #account_type").val("reddit").change();
        $("#create-review #link_field" ).val(searchParams.get('reddit_nickname'));

    } else if (searchParams.has('pikabu_nickname')) {
        $("#create-review #account_type").val("pikabu").change();
        $("#create-review #link_field" ).val(searchParams.get('pikabu_nickname'));

    } else if (searchParams.has('transport_number')) {
        $("#create-review #account_type").val("transport").change();
        $("#create-review #link_field" ).val(searchParams.get('transport_number'));

    } else {
        console.log('undefined account_type')
    }





    $("#create-review #link_field").change(function () {

        // instagram.com/d1ma_antonenko
        if ($('#create-review #account_type').find(":selected").data('name') != 'instagram_link') {
            if ($("#create-review #link_field").val().includes('instagram.com/')) {
                $("#create-review search-form #account_type").val('instagram').change();
                actualize_data();
            }
        }

        // https://www.facebook.com/dima.antonenko.940
        if ($('#create-review #account_type').find(":selected").data('name') != 'facebook_link') {
            if ($("#create-review #link_field").val().includes('facebook.com/')) {
                $("#create-review #account_type").val('facebook').change();
                actualize_data();
            }
        }

        // test@gmail.com
        if ($('#create-review #account_type').find(":selected").data('name') != 'email') {
            if ($("#create-review #link_field").val().match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                $("#create-review #account_type").val('email').change();
                actualize_data();
            }
        }

        // +79198719530
        if ($('#create-review #account_type').find(":selected").data('name') != 'phone') {
            if ($("#create-review #link_field").val().match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)) {
                $("#create-review #account_type").val('phone').change();
                actualize_data();
            }
        }

        // https://www.youtube.com/c/GorailsTV
        if ($('#create-review #account_type').find(":selected").data('name') != 'youtube_link') {
            if ($("#create-review #link_field").val().includes('www.youtube.com/c/')) {
                $("#create-review #account_type").val('youtube').change();
                actualize_data();
            }
        }

        // https://vk.com/id192398263
        if ($('#create-review #account_type').find(":selected").data('name') != 'vk_link') {
            if ($("#link_field").val().includes('vk.com/')) {
                $("#account_type").val('vk').change();
                actualize_data();
            }
        }

        // https://twitter.com/temalebedev
        if ($('#create-review #account_type').find(":selected").data('name') != 'twitter_link') {
            if ($("#create-review #link_field").val().includes('twitter.com/')) {
                $("#create-review #account_type").val('twitter').change();
                actualize_data();
            }
        }

        // https://www.twitch.tv/dota2mc_ru
        if ($('#create-review #account_type').find(":selected").data('name') != 'twitch_link') {
            if ($("#create-review #link_field").val().includes('twitch.tv/')) {
                $("#create-review #account_type").val('twitch').change();
                actualize_data();
            }
        }

        // https://www.tiktok.com/@alisun07
        if ($('#create-review #account_type').find(":selected").data('name') != 'tiktok_link') {
            if ($("#create-review #link_field").val().includes('tiktok.com/@')) {
                $("#create-review #account_type").val('tiktok').change();
                actualize_data();
            }
        }

        // https://www.reddit.com/user/GamingImperial501/
        if ($('#create-review #account_type').find(":selected").data('name') != 'reddit_link') {
            if ($("#create-review #link_field").val().includes('reddit.com/user/')) {
                $("#create-review #account_type").val('reddit').change();
                actualize_data();
            }
        }

        // https://www.linkedin.com/in/dmitry-antonenko-a9669bab/
        if ($('#create-review #account_type').find(":selected").data('name') != 'linkedin_link') {
            if ($("#create-review #link_field").val().includes('linkedin.com/in/')) {
                $("#create-review #account_type").val('linkedin').change();
                actualize_data();
            }
        }

        // https://pikabu.ru/@Prostoilogin
        if ($('#create-review #account_type').find(":selected").data('name') != 'pikabu_link') {
            if ($("#create-review #link_field").val().includes('pikabu.ru/')) {
                $("#create-review #account_type").val('pikabu').change();
                actualize_data();
            }
        }
    });

    function actualize_data() {
        $("#link_field" ).attr( "name", $('#account_type').find(":selected").data('name'));
        $("#link_field" ).attr( "placeholder", $('#account_type').find(":selected").data('placeholder'));
        $("#label_link" ).text( $('#account_type').find(":selected").data('label'));
    }
}

// $('.g-recaptcha').attr("data-callback", "call_recaptcha");
// window.call_recaptcha = call_recaptcha;
//
// function call_recaptcha()
// {
//     console.log("its work");
// }






window.previewFiles = function() {
  preview = document.querySelector('#preview');
  preview.innerHTML = '';

  files = document.querySelector('input[type=file]').files;

  readAndPreview = function(file) {
    if(/\.(jpe?g|png|gif)$/i.test(file.name)) {
      reader = new FileReader();

      reader.addEventListener(
        "load",
        function() {
          photo_album_item_wrap = document.createElement('div');
          photo_album_item_wrap.className = "photo-album-item-wrap";

          photo_album_item = document.createElement('div');
          photo_album_item.className = "photo-album-item";

          photo_item = document.createElement('div');
          photo_item.className = "photo-item";

          image = new Image();
          image.height = 284;
          image.width = 332;
          image.title = file.name;
          image.src = this.result;

          photo_album_item_wrap.appendChild( photo_album_item );
          photo_album_item.appendChild( photo_item );
          photo_item.appendChild( image );

          preview.appendChild( photo_album_item_wrap );
        }
        , false);

      reader.readAsDataURL(file);
    }
  }
  if(files)
    [].forEach.call(files, readAndPreview);
}
