if (document.body.className.match('search') ) {

    search_form_data = window.location.href.split('/').slice(-2)
    // ["instagram", "d1ma_antonenko"]

    $(".search-bar #account_type").val(search_form_data[0]).change();
    $(".search-bar #link_field" ).val(search_form_data[1]);

    $('#account_type').change(function () {
        actualize_data();
    });

    function actualize_data() {
        $(".search-bar #link_field" ).attr( "name", $('.search-bar #account_type').find(":selected").data('name'));
        $(".search-bar #link_field" ).attr( "placeholder", $('.search-bar  #account_type').find(":selected").data('placeholder'));
        $(".search-bar #label_link" ).text( $('.search-bar #account_type').find(":selected").data('label'));
    }
}


if ($(".search-bar").length > 0){
    actualize_data();


    $(".search-bar #link_field").change(function () {

        // console.log($('#account_type').find(":selected").data('placeholder'));
        // console.log($('#account_type').find(":selected").data('name'));

        actualize_data();

        // instagram.com/d1ma_antonenko
        if ($('.search-bar #account_type').find(":selected").data('name') != 'instagram_link') {
            if ($(".search-bar #link_field").val().includes('instagram.com/')) {
                $(".search-bar #account_type").val('instagram').change();
                actualize_data();
            }
        }

        // https://www.facebook.com/dima.antonenko.940
        if ($('.search-bar #account_type').find(":selected").data('name') != 'facebook_link') {
            if ($(".search-bar #link_field").val().includes('facebook.com/')) {
                $(".search-bar #account_type").val('facebook').change();
                actualize_data();
            }
        }

        // test@gmail.com
        if ($('.search-bar #account_type').find(":selected").data('name') != 'email') {
            if ($(".search-bar #link_field").val().match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                $(".search-bar #account_type").val('email').change();
                actualize_data();
            }
        }

        // +79198719530
        if ($('.search-bar #account_type').find(":selected").data('name') != 'phone') {
            if ($(".search-bar #link_field").val().match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)) {
                $(".search-bar #account_type").val('phone').change();
                actualize_data();
            }
        }


        // https://www.youtube.com/c/GorailsTV
        if ($('.search-bar #account_type').find(":selected").data('name') != 'youtube_link') {
            if ($(".search-bar #link_field").val().includes('www.youtube.com/c/')) {
                $(".search-bar #account_type").val('youtube').change();
                actualize_data();
            }
        }

        // https://www.linkedin.com/in/dmitry-antonenko-a9669bab/
        if ($('.search-bar #account_type').find(":selected").data('name') != 'linkedin_link') {
            if ($(".search-bar #link_field").val().includes('linkedin.com/in/')) {
                $(".search-bar #account_type").val('linkedin').change();
                actualize_data();
            }
        }

        // https://vk.com/id192398263
        if ($('.search-bar #account_type').find(":selected").data('name') != 'vk_link') {
            if ($(".search-bar #link_field").val().includes('vk.com/')) {
                $(".search-bar #account_type").val('vk').change();
                actualize_data();
            }
        }

        // https://twitter.com/temalebedev
        if ($('.search-bar #account_type').find(":selected").data('name') != 'twitter_link') {
            if ($(".search-bar #link_field").val().includes('twitter.com/')) {
                $(".search-bar #account_type").val('twitter').change();
                actualize_data();
            }
        }

        // https://www.twitch.tv/dota2mc_ru
        if ($('.search-bar #account_type').find(":selected").data('name') != 'twitch_link') {
            if ($(".search-bar #link_field").val().includes('twitch.tv/')) {
                $(".search-bar #account_type").val('twitch').change();
                actualize_data();
            }
        }

        // https://www.tiktok.com/@alisun07
        if ($('.search-bar #account_type').find(":selected").data('name') != 'tiktok_link') {
            if ($(".search-bar #link_field").val().includes('tiktok.com/@')) {
                $(".search-bar #account_type").val('tiktok').change();
                actualize_data();
            }
        }

        // https://www.reddit.com/user/GamingImperial501/
        if ($('.search-bar #account_type').find(":selected").data('name') != 'reddit_link') {
            if ($(".search-bar #link_field").val().includes('reddit.com/user/')) {
                $(".search-bar #account_type").val('reddit').change();
                actualize_data();
            }
        }

        // https://pikabu.ru/@Prostoilogin
        if ($('.search-bar #account_type').find(":selected").data('name') != 'pikabu_link') {
            if ($(".search-bar #link_field").val().includes('pikabu.ru/')) {
                $(".search-bar #account_type").val('pikabu').change();
                actualize_data();
            }
        }
    });

    function actualize_data() {
        $(".search-bar #link_field" ).attr( "name", $('.search-bar #account_type').find(":selected").data('name'));
        $(".search-bar #link_field" ).attr( "placeholder", $('.search-bar  #account_type').find(":selected").data('placeholder'));
        $(".search-bar #label_link" ).text( $('.search-bar #account_type').find(":selected").data('label'));
    }
}

