require.context('../themes/site/img', true);

require("@rails/ujs").start()
require("@rails/activestorage").start()



require('../themes/site/css/theme-font.css');
require('../themes/site/Bootstrap/dist/css/bootstrap.css');

require('../themes/site/css/main.css');
// require('../themes/site/css/feather-icon.css');

require('../themes/site/css/pages/custom.scss');
require('../themes/site/css/pages/home.scss');
require('../themes/site/css/pages/pages-show.scss');
require('../themes/site/css/pages/search.scss');
require('../themes/site/css/pages/reviews-show.scss');
require('../themes/site/css/pages/sessions-new.scss');
require('../themes/site/css/pages/passwords-new.scss');
require('../themes/site/css/pages/passwords-edit.scss');
require('../themes/site/css/pages/registrations-new.scss');
require('../themes/site/css/pages/reviews-new.scss');
require('../themes/site/css/pages/reviews-edit.scss');
require('../themes/site/css/pages/reviews-newsfeed.scss');
require('../themes/site/css/pages/accounts-show.scss');
require('../themes/site/css/pages/accounts-edit.scss');
require('../themes/site/css/pages/account-bookmarks-my.scss');
require('../themes/site/css/pages/appeals-new.scss');

require("jquery");
import $ from 'jquery';

import I18n from 'i18n-js'
window.I18n = I18n

require('../themes/site/Bootstrap/dist/js/bootstrap.bundle.min.js');
require('../themes/site/js/libs/jquery.magnific-popup.min.js');
require('../themes/site/js/libs-uncompressed/material');
require('../themes/site/js/svg-loader.js');
require('../themes/site/js/pages/home.js');
require('../themes/site/js/pages/reviews-search.js');
require('../themes/site/js/pages/reviews-new.js');
require('../themes/site/js/pages/reviews-show.js');
require('../themes/site/Bootstrap/js/dist/alert.js');
require('../themes/site/Bootstrap/js/dist/modal.js');

require('../themes/site/js/pages/gallery.js');
// require('../themes/site/js/icons/feather-icon/feather.min.js');


