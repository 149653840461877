if (document.body.className.match('reviews-show') ) {
    $("#comment_anon").change(function () {
        $('.nickname_area').empty();
        console.log( $('#comment_anon').find(":selected").val() );

        if ($('#comment_anon').find(":selected").val() == 'true') {
            $('.nickname_area').append('<label class="control-label">' + I18n.t('models.review.author_nickname') + '</label>');
            $('.nickname_area').append('<input placeholder="nickname" class="form-control bg-white" type="text" name="comment[username]" value="' + I18n.t('shared.all.anon') +  '" id="comment_username">');
            $('.nickname_area').append('<span class="material-input"></span>');
            $('#nickname_wrapper').removeClass( "d-none" );
        } else if ($('#review_anon').find(":selected").val() == 'false') {
            $('.comment_anon').empty();
            $('#nickname_wrapper').addClass( "d-none" );
        }
    });

}
